// List of event names which will be sent to footprints api instead of mixpanel
export const FootprintsEvents = [
  // 'Selected Payment Mode',
  'Viewed Offer Ads',
  'Viewed Offer Image',
  // 'Viewed Payment Options',
  'Verified Phone Number',
  'Viewed Home Page',
  'Viewed Landing Page',
  'Viewed Dashboard',
  'Created offer',
  'Created Online Store',
  'Submitted KYC Docs',
  'Resubmitted KYC Docs',
  'Viewed Commerce Plans',
  'Saved Smart Page',
  'Published Smart Page',
  'Unpublished Smart Page',
  'Viewed Online Store',
  'Viewed Offer Page',
];
